import { environment } from '@env/environment';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NotificationService, SpinnerToggleService } from '@avenews/shared-lib';
import Smartlook from 'smartlook-client';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'av-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'agt-platform';
  showSpinner: boolean;
  environment = environment;
  spinnerMessage: { title: string; subtitle: string };
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private spinnerToggleService: SpinnerToggleService,
    private titleService: Title
  ) {
    this.router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationStart) {
        this.spinnerToggleService.showSpinner();
      }
      if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationError || routerEvent instanceof NavigationCancel) {
        this.spinnerToggleService.hideSpinner();
        // if path includes trade-desk
        if (routerEvent.url.includes('trade-desk') || routerEvent.url.includes('loan-files')) {
          if (this.environment.production) {
            Smartlook.init('272e9862416cd48d6345e08b6cec833a48abd5ee', { region: 'eu' });
            Smartlook.record({ forms: true, numbers: true, emails: true, ips: true, api: true });

          }
        }

        const title = this.route?.firstChild?.firstChild?.snapshot.data['title'] || 'Admin Panel';
        this.titleService.setTitle(title);
      }
    });
  }
  ngOnInit() {
    this.notificationService.initNotifications();

    this.spinnerToggleService.spinnerStatus.subscribe((state) => {
      this.showSpinner = state;
    });
    this.spinnerToggleService.getMessage().subscribe((data) => {
      this.spinnerMessage = data;
    });
  }
}